import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EvidenceResponse, File } from '../../interfaces';
import { environment } from '../../../environments/environment';

const coreApiUrl = environment.apiUrl
const evidenceApiUrl = environment.evidenceApiUrl;
const publicToken = environment.apiToken;

@Injectable()
export class EvidenceProvider {

  constructor(
    private http: HttpClient
    ) { }
  /**
   * @description Get evidences by shipment
   * @param {object} params Params to search evidences
   * @returns {EvidenceResponse} Evidences found
   */
  public async getByShipment(params: object): Promise<EvidenceResponse> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return await this.http.post(evidenceApiUrl + '/search', params, {'headers': headers}).toPromise();
  }

  /**
   * @description Get evidence's log associated to the order's identifier
   * @param {string} orderId Order's identifier to get log
   * @returns {object} Evidence's log found
   */
  public async getEvidenceLogByOrderFolio(orderId: string): Promise<object> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return await this.http.get(`${evidenceApiUrl}/log?orderId=${orderId}`, {'headers': headers}).toPromise();
  }

  /**
   * @description get evidences Files by Object Ids
   * @param {object} ids Object with array of Files object Ids
   * @returns {Array<File>} Array of files
   */
  public async getFilesByOids(ids: object): Promise<Array<File>> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    const path = '/solicitudes/detalles/evidencesFile/byOids';
    return await this.http.post<Array<File>>(`${coreApiUrl}${path}`, ids, {'headers': headers}).toPromise();
  }
}