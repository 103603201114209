export const environment = {
  production: window['appGlobalEnv']['production'],
  apiUrl: window['appGlobalEnv']['apiUrl'],
  baseStorageUrl: window['appGlobalEnv']['baseStorageUrl'],
  mainContainer: window['appGlobalEnv']['mainContainer'],
  evidenceContainer: window['appGlobalEnv']['evidenceContainer'],
  evidenceApiUrl: window['appGlobalEnv']['evidenceApiUrl'],
  orderApiUrl: window['appGlobalEnv']['orderApiUrl'],
  shipmentApiUrl: window['appGlobalEnv']['shipmentApiUrl'],
  apiToken: window['appGlobalEnv']['apiToken']
};
