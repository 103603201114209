import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { OrderProvider } from '../../../providers/orders/order-provider.service';
import { Order, SearchOrder, Shipper } from '../../../interfaces';
import { SearchOrderClientComponentLabels, SearchOrderLabels } from './search-order-client.labels';
import { ShipperProvider } from '../../../providers/shipper/shipper-provider.service';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

const SLASH = '/';
const ORDER_HISTORIC_ROUTE = 'search-order/order-tracking';

@Component({
  selector: 'app-search-order-client',
  templateUrl: './search-order-client.component.html',
  styleUrls: ['./search-order-client.component.scss', '../../../app.component.scss']
})
export class SearchOrderClientComponent implements OnInit {
  public labels: SearchOrder;
  public orderId: string;
  public orderFound: Array<Order>;
  public searchingOrder: boolean;
  public searchOrderDataForm: FormGroup;
  public shipper: Shipper;
  public shipperOid: string;
  public showHistoricOrder: boolean;

  constructor(
    private readonly builder: FormBuilder,
    private orderProvider: OrderProvider,
    private router: Router,
    private shipperProvider: ShipperProvider,
    private toast: ToastrAlertsService
  ) {
    this.searchingOrder = false;
    this.labels = SearchOrderClientComponentLabels;
    this.showHistoricOrder = false;
  }

  /**
   * @description Event fires when init component
   */
  public ngOnInit(): void {
    this.initForm();
  }

  /**
   * @description Inits form
   */
  public initForm(): void {
    this.searchOrderDataForm = this.builder.group({
      orderIdentifier: new FormControl(null, [Validators.required, Validators.maxLength(25)])
    });
  }

  /**
   * @description Go to Order Historic Client component
   */
  public goTo(): void {
    const  query ={
      queryParams: {
        folio: this.orderId,
        shipperOid: this.shipperOid
      }
    };
    this.router.navigateByUrl(SLASH, { skipLocationChange: true }).then(() =>
      this.router.navigate([ORDER_HISTORIC_ROUTE], query));
  }

  /**
   * @description Starts the proccess to search the current order
   */
  public async searchOrder(): Promise<void> {
    try {
      this.toast.processingAlert();
      this.searchingOrder = true;
      this.orderId = this.searchOrderDataForm.value.orderIdentifier.trim();
      this.orderFound = await this.orderProvider.getOrderClient(this.orderId);
      if (!this.orderFound.length) {
        this.toast.warningAlert(SearchOrderLabels.notFoundOrder);
        this.searchingOrder = false;
      } else {
        this.shipperOid = this.orderFound[0].shipperId;
        this.shipper = await this.shipperProvider.getShipperByOid(this.shipperOid);
        this.showHistoricOrder = true;
        this.goTo();
      }
    } catch (error) {
      this.searchingOrder = false;
      this.toast.errorAlert(SearchOrderLabels.errorGettingOrder);
    } finally {
      this.toast.closeProcessing();
    }
  }

}
