<div class="general-container">
  <h3 class="title-page">{{ tags.titlePage }}</h3>
  <mat-divider class="mat-divider-header"></mat-divider>
  <button
    id="returnToViewSearchButton"
    mat-button
    [matTooltip]="labels.returnButton"
    matTooltipPosition='above'
    class="button 
           button-return"
    (click)="onClose()">
    <mat-icon class="icon">
      keyboard_arrow_left
    </mat-icon>
  </button>

  <div class="spinner-container" *ngIf="!infoLoaded">
    <mat-progress-spinner mode="indeterminate" diameter="100"></mat-progress-spinner>
  </div>

  <div class="sub-container" *ngIf="orderFound && infoLoaded">

    <div class="folio-container">
      <span class="subtitle-folio text-highlighted">{{ order.folio }}</span>
      <span
        class="subtitle-status"
        [class.subtitle--red]="order.status === tags.statusCanceled"
        [class.subtitle--green]="order.status === tags.statusReleased">
        {{ order.status.toLowerCase() === labels.wrongTransitStatus ? labels.rightTransitStatus.toUpperCase() :
          (order.status.toLowerCase() === labels.wrongSabanaStatus ? labels.rightSabanaStatus.toUpperCase() : order.status.toUpperCase())
        }}
      </span>
      <span class="left-align order-data-value">{{ tags.subtitleLastLog }}</span>
      <table class="single-movement-table" role="presentation">
        <tr>
          <td class="single-log-cell">
            {{ orderLog[orderLog.length - 1].timestamp | date:'dd/MM/yyyy' }}
          </td>
          <td class="single-log-cell">
            {{ orderLog[orderLog.length - 1].timestamp | date:'hh:mm:ss a' }}
          </td>
          <td class="single-log-cell long-cell">
            {{ orderLog[orderLog.length - 1].action}}
          </td>
        </tr>
      </table>
    </div>

    <mat-divider class="folio-divider"></mat-divider>

    <div class="order-resume">

      <div class="multiple-dividers">
        <div class="order-data-container">
          <div class="subtitle-divider-container">
            <div class="subtitle__name">
              <h4>{{ tags.subtitleOrderData }}</h4>
            </div>
            <div class="subtitle__hr left-divider">
              <hr>
            </div>
          </div>
        </div>

        <div class="quantities-container">
          <div class="subtitle-divider-container">
            <div class="subtitle__name">
              <h4>{{ tags.subtitleQuantities }}</h4>
            </div>
            <div class="subtitle__hr">
              <hr>
            </div>
          </div>
        </div>
      </div>

      <table class="resume-table" id="order-data-table" role="presentation">
        <tr>
          <td>{{ tags.orderIdentifier }}:</td>
          <td class="text-highlighted">{{ order.identifier ? order.identifier : tags.noInfo }}</td>
          <td class="order-data-property">{{ tags.orderDeliveryDate }}:</td>
          <td class="order-data-value">
            {{ order.deliveryDate ? (order.deliveryDate | date : 'dd/MM/yyyy') : tags.noInfo }}
            {{ order.appointmentHour ? ('- ' + order.appointmentHour) : '' }}
          </td>
          <td rowspan="2" class="order-data-value quantity-numbers">{{ order.boxes ? order.boxes : 0 }}</td>
          <td rowspan="2" class="order-data-value quantity-numbers">{{ order.pieces ? order.pieces : 0 }}</td>
          <td rowspan="2" class="order-data-value quantity-numbers">{{ order.pallets ? order.pallets : 0 }}</td>
          <td rowspan="2" class="order-data-value quantity-numbers">{{ order.weight ? order.weight + ' kg' : 0 }}</td>
          <td rowspan="2" class="order-data-value quantity-numbers">{{ order.volume ? order.volume : 0 }}
            <span> m<sup>3</sup></span>
          </td>
        </tr>
        <tr>
          <td>{{ tags.orderInternalReference }}:</td>
          <td [class.no-info]="!order.internalReference">{{ order.internalReference ? order.internalReference : tags.noInfo }}</td>
          <td class="order-data-property">{{ tags.orderAccount }}:</td>
          <td class="order-data-value text-highlighted" [class.no-info]="!order.account">{{ order.account ? order.account.name : tags.noInfo }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>{{ tags.orderInvoice }}:</td>
          <td [class.no-info]="!order.invoice">{{ order.invoice ? order.invoice : tags.noInfo }}</td>
          <td rowspan="1" class="order-data-property">{{ tags.orderCreation }}:</td>
          <td rowspan="1" class="order-data-value">{{ order.creationDate ? (order.creationDate | date : 'dd/MM/yyyy') : tags.noInfo }}</td>
          <td rowspan="3" class="quantity-tags">{{ tags.orderBoxes }}</td>
          <td rowspan="3" class="quantity-tags">{{ tags.orderPieces }}</td>
          <td rowspan="3" class="quantity-tags">{{ tags.orderPallets }}</td>
          <td rowspan="3" class="quantity-tags">{{ tags.orderWeight }}</td>
          <td rowspan="3" class="quantity-tags">{{ tags.orderVolume }}</td>
        </tr>
        <tr>
          <td>{{ tags.orderGrouper }}:</td>
          <td [class.no-info]="!order.orderGrouper">{{ order.orderGrouper ? order.orderGrouper : tags.noInfo }}</td>
        </tr>
      </table>
    </div>

    <div class="container-places">
      <div id='originContainer' class="origin-container">
        <div class="origin-table-container">
          <div class="origin-header">
            <div class="subtitle-divider-container">
              <div class="subtitle__name">
                <h4>{{ tags.subtitleOrigin }}</h4>
              </div>
              <div class="subtitle__hr left-divider">
                <hr>
              </div>
            </div>
          </div>

          <table class="resume-table" id="origin-table" role="presentation">
            <tr>
              <td>{{ tags.orderOriginWarehouse }}:</td>
              <td class="text-highlighted">{{ order.origin ? order.origin.name : tags.noInfo }}</td>
            </tr>
            <tr>
              <td rowspan="1">{{ tags.orderOriginAddress }}:</td>
              <td rowspan="1" class="address-cell">{{ order.origin ? order.origin.address : tags.noInfo }}</td>
            </tr>
            <tr *ngIf="order.type === tags.orderTypePickup && order.origin.phone">
              <td rowspan="1">{{ tags.orderPhone }}:</td>
              <td rowspan="1" class="address-cell">{{ order.origin.phone }}</td>
            </tr>
            <tr *ngIf="order.type === tags.orderTypePickup && order.origin.responsable">
              <td rowspan="1">{{ tags.orderResponsable }}:</td>
              <td rowspan="1" class="address-cell">{{ order.origin.responsable }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div id='destinationContainer' class="destination-container">
        <div class="destination-table-container">
          <div class="destination-header">
            <div class="subtitle-divider-container">
              <div class="subtitle__name">
                <h4>{{ tags.subtitleDestination }}</h4>
              </div>
              <div class="subtitle__hr left-divider">
                <hr>
              </div>
            </div>
          </div>

          <table class="resume-table" id="destination-table" role="presentation">
            <tr>
              <td>{{ tags.orderDestinationName }}:</td>
              <td class="text-highlighted">{{ order.destination ? order.destination.name : tags.noInfo }}</td>
            </tr>
            <tr>
              <td rowspan="1">{{ tags.orderDestinationAddress }}:</td>
              <td rowspan="1" class="address-cell">{{ order.destination ? order.destination.address : tags.noInfo }}</td>
            </tr>
            <tr>
              <td>{{ tags.orderDestinationMunicipality }}:</td>
              <td>{{ order.origin ? order.destination.municipality : tags.noInfo }}</td>
            </tr>
            <tr>
              <td>{{ tags.orderDestinationPostalCode }}:</td>
              <td>{{ order.destination ? order.destination.postalCode : tags.noInfo }}</td>
            </tr>
            <tr *ngIf="order.destination.phone">
              <td rowspan="1">{{ tags.orderPhone }}:</td>
              <td rowspan="1" class="address-cell">{{ order.destination.phone }}</td>
            </tr>
            <tr *ngIf="order.destination.responsable">
              <td rowspan="1">{{ tags.orderResponsable }}:</td>
              <td rowspan="1" class="address-cell">{{ order.destination.responsable }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div
      *ngIf="order.status === 'Liberada'"
      class="destination-container"
      fxLayout="row"
      style="width: 150%;">
      <div id="Evidences" fxFlex="66">
        <div class="destination-header">
          <div class="subtitle-divider-container">
            <div class="subtitle__name">
              <h4>{{ tags.subtitleEvidences }}</h4>
            </div>
            <div class="subtitle__hr left-divider">
              <hr>
            </div>
          </div>
        </div>
        <div id="evidences_carousel" fxLayout="row">
          <div fxFlex="40">
            <table
              aria-describedby="evidence-table"
              class="resume-table"
              id="evidence-table-properties"
              role="presentation">
              <tr>
                <td class="order-data-property order-property-data-evidence">{{ tags.orderReceivedBy }}:</td>
                <td class="order-data-value" [class.no-info]="destinationReceptor === tags.noInfo">{{ destinationReceptor }}</td>
              </tr>
              <tr>
                <td class="order-data-property order-property-data-evidence">{{ tags.orderEvidenceFolio }}:</td>
                <td class="order-data-value" [class.no-info]="destinationFolio === tags.noInfo">{{ destinationFolio }}</td>
              </tr>
              <tr>
                <td rowspan="1" class="order-data-property order-property-data-evidence">{{ tags.orderDeliveredDate }}:</td>
                <td
                  rowspan="1"
                  class="order-data-value"
                  [class.no-info]="destinationDate === tags.noInfo">
                  {{ destinationDate === tags.noInfo ? tags.noInfo : (destinationDate | date:'dd/MM/yyyy hh:mm:ss a') }}
                </td>
              </tr>
            </table>
            <div fxLayout="row">
              <div fxLayout="column" fxFlex="50">
                <div fxLayout="row" fxFlex="100">
                  <div fxFlex="100" class="ellipsis" [matTooltip]="proofDelivery">
                    <strong> {{ labels.proofDelivery }} </strong>
                  </div>
                </div>
                <div fxLayout="row" fxFlex="100">
                  <div fxFlex="100" class="ellipsis">
                    <button
                      id="downloadButtonProofDelivery"
                      [disabled]="order.status !== 'Entregada' || order.status !== 'Parcial' || order.status !== 'No Entregada' || order.status !== 'Rechazada' || order.status !== 'Liberada'"
                      class="proof-delivery-button"
                      printSectionId="print-section"
                      [printTitle]="getPrintTitle(order)"
                      ngxPrint
                      [useExistingCss]="true">
                      <mat-icon class="download-button">
                      get_app
                    </mat-icon>
                    </button>
                  </div>
                  <app-proof-delivery
                    *ngIf="downloadProofDelivery"
                    [hidden]=true
                    id="print-section"
                    [shipment]="shipmentRequest"
                    [orderData]="order">
                  </app-proof-delivery>
                </div>
              </div>
            </div>
          </div>
          <div fxFlex="25">
          </div>
          <div fxFlex="35">
            <div class="carousel-container">
              <app-mini-carousel
                [title]="carouselTitle"
                [images]="carouselImages"
                [orderFolio]="order.folio"
                [orderOid]="orderOid"
                [displayInfo]="true"
                [downloadService]="downloadService"
                [evidenceAPIInfo]="orderEvidenceAPIInfo"
                [mobilityInfo]="orderMobilityEvidences"
                [shipperOid]="shipperOid"
                >
              </app-mini-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle-divider-container">
      <div class="subtitle__name">
        <h4>{{ tags.subtitleShipments }}</h4>
      </div>
      <div class="subtitle__hr">
        <hr>
      </div>
    </div>

    <div class="shipment-container">

      <div *ngIf="!shipments.length">
        <p class="no-info center">({{ tags.noInfo }})</p>
      </div>

      <table
        mat-table
        [dataSource]="shipmentDataSource"
        *ngIf="shipments.length"
        aria-describedby="shipment">
        <ng-container matColumnDef="shipment">
          <th
            id="shipment"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.headerShipment }}
          </th>
          <td mat-cell *matCellDef="let shipment">
            <strong>{{ shipment.shipmentFolio }}</strong>
          </td>
        </ng-container>
        <ng-container matColumnDef="mobilityId">
          <th
            id="mobilityId"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.headerMobility }}
          </th>
          <td mat-cell *matCellDef="let shipment" class="hyperlink-column">
            <a
              class="hyperlink"
              [routerLink]="['/journey-menu/details']"
              [queryParams]="{ id: shipment.request }"
              target="_blank">
              {{ shipment.request }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="internalReference">
          <th
            id="internalReference"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerInternalReference }}
          </th>
          <td
            mat-cell
            *matCellDef="let shipment"
            [class.no-info]="!shipment.internalReference">
            {{ shipment.internalReference ? shipment.internalReference : tags.noInfo }}
          </td>
        </ng-container>
        <ng-container matColumnDef="carrier">
          <th
            id="carrier"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerCarrier }}
          </th>
          <td mat-cell *matCellDef="let shipment">
            {{ shipment.carrier }}
          </td>
        </ng-container>
        <ng-container matColumnDef="driver">
          <th
            id="driver"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.headerDriver }}
          </th>
          <td mat-cell *matCellDef="let shipment" [matTooltip]="shipment.driver.length > 15 ? shipment.driver : ''" class="cell--overflow">
            {{ shipment.driver }}
          </td>
        </ng-container>
        <ng-container matColumnDef="plates">
          <th
            id="plates"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerPlates }}
          </th>
          <td mat-cell *matCellDef="let shipment">
            {{ shipment.plates }}
          </td>
        </ng-container>
        <ng-container matColumnDef="originWarehouse">
          <th
            id="originWarehouse"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.headerOriginWarehouse }}
          </th>
          <td mat-cell *matCellDef="let shipment">
            {{ shipment.origin }}
          </td>
        </ng-container>
        <ng-container matColumnDef="inRouteDate">
          <th
            id="inRouteDate"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerInRouteDate }}
          </th>
          <td mat-cell *matCellDef="let shipment">
            <div *ngIf="!shipment.inRouteDate" class="no-info">
              {{ tags.inWarehouseMessage }}
            </div>
            <div *ngIf="shipment.inRouteDate">
              {{ shipment.inRouteDate | date:'dd/MM/yyyy' }}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th
            id="status"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerStatus }}
          </th>
          <td
            mat-cell
            *matCellDef="let shipment">
            <span
              class="pill-badge"
              [ngClass]="{
                'pill-badge--light': shipment.status === tags.shipmentStatusLast,
                'pill-badge--strong': shipment.status === tags.shipmentStatusPast
              }">
              {{ shipment.status }}
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="shipmentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: shipmentColumns;"></tr>
      </table>

    </div>

    <div class="subtitle-divider-container">
      <div class="subtitle__name">
        <h4>{{ tags.subtitleLogs }}</h4>
      </div>
      <div class="subtitle__hr">
        <hr>
      </div>
    </div>

    <div class="history-container" id="history">

      <table mat-table [dataSource]="logDataSource" aria-describedby="history">
        <ng-container matColumnDef="date">
          <th
            id="date"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerLogDate }}
          </th>
          <td mat-cell *matCellDef="let log">
            {{ log.timestamp | date:'dd/MM/yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="time">
          <th
            id="time"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerLogHour }}
          </th>
          <td mat-cell *matCellDef="let log">
            {{ log.timestamp | date:'hh:mm:ss a' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="movement">
          <th
            id="movement"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerLogMovement }}
          </th>
          <td mat-cell *matCellDef="let log">
            {{
              log.status.toLowerCase() === labels.wrongTransitStatus ? labels.rightTransitStatus :
              (log.status.toLowerCase() === labels.wrongSabanaStatus ? labels.rightSabanaStatus : log.status)
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th
            id="description"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell long-cell">
            {{ tags.headerLogDescription }}
          </th>
          <td mat-cell *matCellDef="let log">
            {{ log.action }}
          </td>
        </ng-container>
        <ng-container matColumnDef="user">
          <th
            id="user"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.headerLogUser }}
          </th>
          <td mat-cell *matCellDef="let log">
            {{ log.user }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="historyColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: historyColumns;"></tr>
      </table>

    </div>

    <div class="subtitle-divider-container">
      <div class="subtitle__name">
        <h4>{{ tags.subtitleProducts }}</h4>
      </div>
      <div class="subtitle__hr">
        <hr>
      </div>
    </div>

    <div class="product-container">

      <div *ngIf="!products.length">
        <p class="no-info center">({{ tags.noInfo }})</p>
      </div>

      <table
        mat-table
        [dataSource]="productDataSource"
        *ngIf="products.length"
        aria-describedby="products">

        <ng-container matColumnDef="name">
          <th
            id="productName"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.productHeaderName }}
          </th>
          <td mat-cell *matCellDef="let product">
            <strong>{{ product.name }}</strong>
          </td>
        </ng-container>

        <ng-container matColumnDef="code">
          <th
            id="productCode"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.productHeaderCode }}
          </th>
          <td mat-cell *matCellDef="let product">
            <strong>{{ product.code }}</strong>
          </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th
            id="productTotal"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.productHeaderTotal }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.total }}
          </td>
        </ng-container>

        <ng-container matColumnDef="unit">
          <th
            id="productUnit"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.productHeaderUnit }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.unit }}
          </td>
        </ng-container>

        <ng-container matColumnDef="accepted">
          <th
            id="productAccepted"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.productHeaderAccepted }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.accepted }}
          </td>
        </ng-container>

        <ng-container matColumnDef="rejected">
          <th
            id="productRejected"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.productHeaderRejected }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.rejected }}
          </td>
        </ng-container>

        <ng-container matColumnDef="reason">
          <th
            id="productReason"
            mat-header-cell
            *matHeaderCellDef
            class="cell--wide regular-cell">
            {{ tags.productHeaderReason }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.reasonWhyRejected ? product.reasonWhyRejected : '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="price">
          <th
            id="productPrice"
            mat-header-cell
            *matHeaderCellDef
            class="regular-cell">
            {{ tags.productHeaderPrice }}
          </th>
          <td mat-cell *matCellDef="let product">
            {{ product.price ? product.price : '-' }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="productColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: productColumns;"></tr>
      </table>

    </div>

  </div>
</div>
