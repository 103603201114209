import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ArrayOids,
  FileResponse,
  Order,
  OrderApiLog,
  OrderIdentifiersBody,
  OrderIdentifiersResponse,
  OrdersApi,
  ShipmentRequest
} from '../../interfaces'
import { environment } from './../../../environments/environment';

const coreApiUrl = environment.apiUrl
const orderApiUrl = environment.orderApiUrl;
const publicToken = environment.apiToken;

@Injectable()
export class OrderProvider {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Gets Order by order folio
   * @param {string} orderFolio Order folio
   * @returns {Array<Order>} Order Found
   */
  public async getOrderClient(orderFolio: string): Promise<Array<Order>> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return await this.http.get<Array<Order>>(`${coreApiUrl}/getOrderPublicClient/order?order=${orderFolio}`, {'headers': headers}).toPromise();
  }

  /**
   * @description Gets shipments request by shipment request Id
   * @param {string} shipmentReqId Shipment request id
   * @param {string} shipperOid ShipperOid
   * @returns {Array<ShipmentRequest>} Shipment Request found
   */
  public async getOrdersByShipment(shipmentReqId: string, shipperOid: string): Promise<Array<ShipmentRequest>> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return this.http.get<Array<ShipmentRequest>>(coreApiUrl + '/solicitudes/' + shipmentReqId + '/evidencias?embarcador=' + shipperOid, {'headers': headers})
      .toPromise();
  }

  /**
   * @description Gets Order log
   * @param {string} orderOid Order Oid
   * @returns {OrderApiLog} Order log found
   */
  public async getOrderLog(orderOid: string): Promise<OrderApiLog> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return this.http.get<OrderApiLog>(`${orderApiUrl}/order/${orderOid}/logs`, {'headers': headers}).toPromise();
  }

  /**
   * @description it retrieves an array of orders found by the ObjectId of these
   * @param {<ArrayOids>} ordersId Oid of the orders to get
   * @param {<string>} shipperOid Shipper Oid
   * @return {Array<OrderApi>} Order found
   */
  public async getOrdersByOids(ordersId: ArrayOids, shipperOid: string): Promise<OrdersApi[]> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return this.http.post<Array<OrdersApi>>(orderApiUrl + '/tenant/' + shipperOid + '/byOids', ordersId, {'headers': headers}).toPromise();
  }

  /**
   * @description Get orders based on identifiers
   * @param {OrderIdentifiersBody} bodyReq The body with the identifiers to find
   * @param {string} shipperOid Current shipperOid
   * @returns {Array<OrderIdentifiersResponse>} A promise with the orders found
   */
  public async getOrdersByIdentifiers(bodyReq: OrderIdentifiersBody, shipperOid: string): Promise<OrderIdentifiersResponse> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return this.http.post<OrderIdentifiersResponse>(`${orderApiUrl}/tenant/${shipperOid}/identifiers`, bodyReq, {'headers': headers}).toPromise();
  }

  /**
   * @description Get signature file
   * @param {string} fileOid Signature file Object Id
   * @returns {FileResponse} File Response body
   */
  public async getFile(fileOid: string): Promise<FileResponse> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return this.http.get<FileResponse>(`${coreApiUrl}/archivos/${fileOid}`, {'headers': headers}).toPromise();
  }

}