import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ShipmentOrderReceptionStatus } from './../../interfaces/order-reception';
import { ShipmentRequest } from '../../interfaces';

const coreApiUrl = environment.apiUrl;
const shipmentApiUrl = environment.shipmentApiUrl;
const publicToken = environment.apiToken;

@Injectable()
export class ShipmentProvider {

  constructor(
    private http: HttpClient
    ) { }
  /**
   * @desctiption Gets shipment by shipmentID
   * @param {string} tenantId Current tenant Oid
   * @param {string} shipperId Current shipper Oid
   * @param {string} shipmentToSearch Shipper identifier
   * @returns {Object} Current shipment object
   */
  public async getShipmentByShipmentId(tenantId: string, shipperId: string, shipmentToSearch: string): Promise<Array<ShipmentOrderReceptionStatus>> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return await this.http.get<Array<ShipmentOrderReceptionStatus>>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/' + shipperId
      + '/shipment/' + shipmentToSearch, {'headers': headers}).toPromise();
  }

  /**
   * @description Gets shipment request by Oid
   * @param {string} shipmentRequestOid Shipment request Oid
   * @returns {ShipmentRequest} Shipment request found
   */
  public async getShipmentRequestByOid(shipmentRequestOid: string): Promise<ShipmentRequest> {
    const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': publicToken});
    return await this.http.get<ShipmentRequest>(coreApiUrl + '/solicitudes/' + shipmentRequestOid, {'headers': headers}).toPromise();
  }

}