export enum OrderHistoryLiteLabels {
  proofDelivery = 'Prueba de Entrega',
  returnButton = 'Regresar',
  rightSabanaStatus = 'En Sábana',
  rightTransitStatus = 'En Tránsito',
  statusShipmentLast = 'Último',
  statusShipmentPast = 'Pasado',
  toastErrorEvidences = 'Error al consultar las evidencias',
  toastErrorEvidencesMobility = 'Error al traer las evidencias de mobility',
  toastErrorOrder = 'Error al consultar la orden',
  toastErrorRequest = 'Ocurrió un error al recuperar la solicitud de Mobility',
  toastErrorShipment = 'Ocurrió un error al recuperar el embarque',
  toastWarnNoOrder = 'No hay ninguna orden por buscar',
  toastWarnNoShipment = 'No se encontraron embarques',
  wrongSabanaStatus = 'en sabana',
  wrongTransitStatus = 'en transito'
}

export enum OrderHistoryLiteTags {
  doesNotApply = 'N/A',
  headerCarrier = 'Línea de Transporte',
  headerDriver = 'Operador',
  headerInRouteDate = 'Fecha en Ruta',
  headerInternalReference = 'Referencia Interna',
  headerLogDate = 'Fecha',
  headerLogDescription = 'Descripción',
  headerLogHour = 'Hora',
  headerLogMovement = 'Movimiento',
  headerLogUser = 'Usuario',
  headerMobility = 'Solicitud',
  headerOriginWarehouse = 'Bodega de Origen',
  headerPlates = 'Placas',
  headerShipment = 'Embarque',
  headerStatus = 'Estatus',
  inWarehouseMessage = 'En Bodega',
  noInfo = 'Sin Información',
  noInfoMessage = 'No se encontró información relacionada a la orden ingresada',
  orderAccount = 'Cuenta',
  orderBoxes = 'Cajas',
  orderCreation = 'Fecha de Creación',
  orderDeliveredDate = 'Última Evidencia',
  orderDeliveryDate = 'Entrega programada',
  orderDestinationAddress = 'Dirección',
  orderDestinationMunicipality = 'Municipio',
  orderDestinationName = 'Nombre',
  orderDestinationPostalCode = 'Código Postal',
  orderEvidenceFolio = 'Folio de Evidencia',
  orderGrouper = 'Pedido',
  orderIdentifier = 'Orden del cliente',
  orderInternalReference = 'Referencia Interna',
  orderInvoice = 'Factura',
  orderOriginAddress = 'Dirección de Origen',
  orderOriginMunicipality = 'Municipio de origen',
  orderOriginWarehouse = 'Bodega de Origen',
  orderPallets = 'Tarimas',
  orderPhone = 'Teléfono',
  orderPieces = 'Piezas',
  orderReceivedBy = 'Recibido por',
  orderResponsable = 'Contacto',
  orderTypePickup = 'Recolección',
  orderVisits = 'Número de visitas',
  orderVolume = 'Volumen',
  orderWeight = 'Peso',
  productHeaderAccepted = 'Aceptados',
  productHeaderCode = 'Código',
  productHeaderName = 'Nombre',
  productHeaderPrice = 'Precio',
  productHeaderReason = 'Razón de Rechazo',
  productHeaderRejected = 'Rechazados',
  productHeaderTotal = 'Total',
  productHeaderUnit = 'Unidad',
  shipmentStatusLast = 'Último',
  shipmentStatusPast = 'Pasado',
  statusCanceled = 'Cancelada',
  statusReleased = 'Liberada',
  subtitleDestination = 'Destino',
  subtitleEvidences = 'Evidencias',
  subtitleIncidences = 'Incidencias',
  subtitleLastLog = 'Último Movimiento',
  subtitleLogs = 'Historial de Movimientos',
  subtitleOrderData = 'Datos de la Orden',
  subtitleOrigin = 'Origen',
  subtitleProducts = 'Productos',
  subtitleQuantities = 'Datos de la Mercancía',
  subtitleShipments = 'Embarques',
  titlePage = 'Histórico de Orden'
}

export enum GenericIncidenceTags {
  detailTitle = 'Detalle de Incidencia',
  tooltipDownload = 'Descarga las incidencias de la orden'
}

export enum GenericTags {
  cancelTag = 'Cancelar',
  idAlreadyTaken = 'El identificador ya existe, intenta con otro',
  is = 'es',
  multipleOrderSearch = 'Búsqueda múltiple',
  nameAlreadyTaken = 'El nombre ya existe, intenta con otro',
  required = 'requerido',
  requiredField = 'Campo requerido',
  saveTag = 'Guardar',
  searchCriteria = 'Criterio de búsqueda',
  send = 'Enviar',
  updateTag = 'Actualizar',
  withoutSignature = 'SinFirma',
  withSignature = 'ConFirma'
}
