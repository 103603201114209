<scf-header
  [solution]="solution"
  [showUserPanel]="false">
</scf-header>
<scf-sidebar
  [solution]="solution"
  [items]="" [ngClass]="{'logout-request': false}">
  <router-outlet scf-workspace>
  </router-outlet>
</scf-sidebar>

