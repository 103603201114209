export enum MiniCarouselTags {
  dialogPreviewTitle = 'Detalle de Evidencia',
  errorGettingEvidences = 'Error al obtener las evidencias',
  noInfo = 'Sin información',
  oneFile = 'En un solo archivo',
  separatedFiles = 'En archivos separados',
  toastErrorDownload = 'Ocurrió un error al descargar el archivo',
  toastErrorOrder = 'Error al consultar las evidencias de la orden',
  toastSuccessDownload = 'Tu archivo está listo',
  tooltipDownload = 'Descarga las evidencias de la orden',
  tooltipDownloadEvidences = 'Descargar Evidencias',
  tooltipImage = 'Ver detalle'
}
